import type { CategoriesViewProps, CategoryProps, GameProps } from '../../types';
import { CardTypeEnum } from '../../types/enum';

import * as React from 'react';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';
import { useTheme } from '@mui/material/styles';
import { useAppContext } from '../../contexts/AppContext';
import { Title } from '../../components/Title';
import { Link } from '../../components/Link';
import { CollectionSection } from '../../components/CollectionSection';
import Container from '@mui/material/Container';
import useLocalStorage from '../../utils/use-local-storage';
import { useTranslation } from 'react-i18next';
import useFlattenedGameData from '../../utils/useFlattenArray';

const CarouselList = dynamic<any>(() => import('../../components/CarouselList').then((mod) => mod.CarouselList), {
  ssr: false,
});

export const CollectionsC = (props: CategoriesViewProps) => {
  const { collections } = props;
  const { ref, inView } = useInView();
  const { t } = useTranslation('collections_c');
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();
  const theme = useTheme();

  const cardType = componentOptions?.component_game_carousel_card_type;

  const [lastPlayedGames] = useLocalStorage<GameProps[] | undefined>('lastplayed:current', []);
  const showLastPlayedCarousel = componentOptions?.component_homepage_show_last_played_carousel;

  //change 'baby' to 'recommended'
  const recommendedCollection = collections.find((c) => c.code.includes('recommended')) || { games: [] };

  const flattenedData = useFlattenedGameData(lastPlayedGames || []);

  return (
    <Container ref={ref} maxWidth={false} disableGutters={true}>
      <CarouselList
        items={collections[0]?.games}
        padding={0}
        breakpoints={{
          0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
        }}
        cardOptions={{
          width: 1920,
          height: 480,
          sizes: '100vw',
          layout: 'responsive',
          template: cardType || CardTypeEnum.BIG,
        }}
        grabCursor
      />
      {showLastPlayedCarousel && flattenedData && flattenedData.length > 0 && (
        <CollectionSection key={'last-played-section'}>
          <Title component="h2" variant="h2">
            <Link href={'/'} title={t('my_last_played', 'My Last Played')} underline="hover">
              {t('my_last_played', 'My Last Played')}
            </Link>
          </Title>
          <CarouselList
            padding={theme.spacing(2)}
            items={[
              ...flattenedData,
              ...recommendedCollection.games.map((game) => ({
                ...game,
                trend: true,
              })),
            ]}
            cardOptions={{
              width: componentOptions.component_card_width || 512,
              height: componentOptions.component_card_height || 288,
              sizes: '(min-width:1200px) 16.666vw, (min-width:900px) 25vw, (min-width:600px) 33.333vw, 50vw',
              layout: 'responsive',
              template: cardType || CardTypeEnum.SIMPLE,
              typographyOptions: {
                align: 'left',
              },
            }}
          />
        </CollectionSection>
      )}
      {collections &&
        collections
          .filter((c) => !c.code.includes('recommended'))
          .map(
            (collection: CategoryProps, index: number) =>
              index !== 0 &&
              (inView || index === 1) && (
                <CollectionSection key={collection.slug}>
                  <Title component="h1" variant="h1" align="left">
                    <Link
                      href={`/${collection?.type}/${collection?.slug}`}
                      title={collection?.name || undefined}
                      underline="hover"
                    >
                      {collection.name}
                    </Link>
                  </Title>
                  <CarouselList
                    padding={theme.spacing(2)}
                    items={[...Array(12)].map((slot, index) => collection?.games?.[index])}
                    cardOptions={{
                      width: componentOptions.component_card_width || 512,
                      height: componentOptions.component_card_height || 288,
                      sizes: '(min-width:1200px) 16.666vw, (min-width:900px) 25vw, (min-width:600px) 33.333vw, 50vw',
                      layout: 'responsive',
                      template: cardType || CardTypeEnum.SIMPLE,
                      typographyOptions: {
                        align: 'center',
                      },
                    }}
                  />
                </CollectionSection>
              )
          )}
    </Container>
  );
};
